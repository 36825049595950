import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Button, { VisualType, VisualVariant } from 'common/Button';
import Banner from 'components/Banner';
import { BannerMode } from 'components/Banner/models.d';
import SecondaryNavigation from 'components/SecondaryNavigation';
import { bodyAdapter } from 'utils/bodyAdapter';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { SimplePagePageProps } from './models';

import './SimplePagePage.scss';

function SimplePageAccordionItem({ item }: { item: PageContent.AccordionType }) {
  const keys = useId(item.link?.length);

  return (
    <>
      {item.link?.map((linkitem, index) => (
        <a key={keys[index]} href={linkitem?.url} className="accordion__item">
          {item.label}
        </a>
      ))}
    </>
  );
}

function SimplePageAccordion({ items }: { items: PageContent.AccordionType[] }) {
  const keys = useId(items.length);

  return (
    <div className="accordion">
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SimplePageAccordionItem key={keys[index]} item={item} />
      ))}
    </div>
  );
}

function handleOpenLink(url?: string) {
  if (!url) return;
  window.location.href = url;
}

function SimpleButtons(button: PageContent.SimpleButtonType) {
  const keys = useId(button.link.length);

  return button.link.map((link, index) => (
    <Button
      key={keys[index]}
      type="button"
      visualType={VisualType.Opener}
      visualVariant={VisualVariant.Dark}
      onClick={() => handleOpenLink(link?.url)}
    >
      {link.name}
    </Button>
  ));
}

function SimplePageBody({ body }: { body: PageContent.SimplePageBodyType }) {
  return (
    <div className="body">
      {body.text ? (
        <div className="body__text" dangerouslySetInnerHTML={{ __html: body.text }} />
      ) : null}
      {body.accordion ? <SimplePageAccordion items={body.accordion} /> : null}
      {body.button ? (
        <div className="body__button">{body.button.map((button) => SimpleButtons(button))}</div>
      ) : null}
    </div>
  );
}

const SimplePagePage: FC<{
  data: SimplePagePageProps;
  pageContext: PageContent.PageContext;
  location: PageContent.LocationType;
}> = ({
  data: {
    allSimplePage: {
      nodes: [{ langProps, body, banner, seo }],
    },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const adaptedBody = bodyAdapter(body);
  const secondaryNavigationAnchors = adaptedBody.map(
    ({ value }) => value.secondaryNavigationAnchor
  );

  return (
    // <Layout langProps={langProps} className="simplePage-page">
    <Layout {...{ seo, langProps, breadcrumbs, pagePathname }} className="simplePage-page">
      {secondaryNavigationAnchors ? (
        <SecondaryNavigation anchors={secondaryNavigationAnchors} lang={langProps.lang} />
      ) : null}
      {banner && <Banner {...banner[0]} mode={BannerMode.article} />}
      {body && (
        <div className="bodySection">
          {body.map((e, i, arr) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>
              <SimplePageBody body={e} />

              {arr.length - 1 !== i ? (
                <hr className="horizontal-line horizontal-line--with-margin-left-right" />
              ) : null}
            </div>
          ))}
        </div>
      )}
    </Layout>
  );
};

// query SimplePageQuery($pageId: String, $lang: String) {
export const query = graphql`
  query($link: String) {
    allSimplePage(filter: { link: { eq: $link } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        banner {
          ...BannerFragment
        }
        body {
          text
          accordion {
            label
            link {
              url
            }
          }
          button {
            link {
              name
              udi
              url
              icon
            }
          }
        }
        seo {
          ...SEOStructureFragment
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default SimplePagePage;
